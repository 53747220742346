import React from 'react';
import TeamIntroduction from '../components/About/Introduction';
import ContentWrapper from '../components/ContentWrapper';
import Title from '../images/titles/om_oss.svg';

const About = () => {
    return <> <ContentWrapper>
        {/*<h1 className="text-center mt-16 mb-12 text-3xl  text-gray-800">Om oss</h1>*/}
        <img src={Title} className="w-72 text-center mx-auto mt-16 mb-12" />
         <div className="mb-24 px-4 md:px-28 mb-24">
          <p className="hidden text-3xl">Historien om The Branch</p>
          <p className="hidden max-w-3xl mt-4 mb-16 "> 
              Vi i The Branch består av tre gutter, fra nord, sør og midt i Norge. 
              Da vi gikk på animasjonslinja på Høgskulen i Volda, fant vi ut av at vi hadde god kjemi. 
              Sammen produserte vi en rekke kortfilmer som ble en megasuksess, (blant et par stykker).
              Da vi gikk siste året på studiet, bestemte vi oss for å følge drømmen; å starte en bedrift. 
              Bedriften ble oppkalt etter vårt første samarbeidsprosjekt;  ‘On The Branch’. 
              Vi meldte oss derfor på et entreprenørskapskurs og var i gang som et Aksjeselskap i løpet av sommeren 2021.
          </p>
         <TeamIntroduction />
        </div>
    </ContentWrapper>
    </>

}

export default About;